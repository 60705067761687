import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { LandingPage } from './Pages/LandingPage';
import Privacy from './Pages/Privacy';
import Terms from './Pages/Terms';
import DMCA from './Pages/DMCA';
import Discloser from './Pages/Discloser';
import ErrorPage from './Pages/Error-page';
function App() {

  const router = createBrowserRouter([
    {
      path: "/",
      element: <LandingPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/dmca",
      element: <DMCA />,
    },
    {
      path: "/discloser",
      element: <Discloser />,
    },

  ]);
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
