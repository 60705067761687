import { Link } from "react-router-dom";

export default function Terms() {
  return (
    <div class=" m-4 sm:m-40 block mt-8 sm:mt-8 text-sm text-black-950 text-center dark:text-gray-400">
      <Link to={`/`} class=" mt-2 underline text-5xl font-bold tracking-tight text-orange-500 sm:text-5xl">
        <b>Life Balance Lovers</b>
      </Link>

      <h1 class=" mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        <b>Terms of Service</b></h1>


      <p className="mt-2 text-xl leading-8 text-slate-950">This Agreement was last modified on November 30, 2023.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">Please read these Terms of Service ("Agreement", "Terms of Service") carefully before using this website ("the Site"). This Agreement sets forth the legally binding terms and conditions for your use of the Site.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">By accessing or using the Site in any manner, including, but not limited to, visiting or browsing the Site or contributing content or other materials to the Site, you agree to be bound by these Terms of Service. Capitalized terms are defined in this Agreement.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Intellectual Property
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">The Site and its original content, features and functionality are owned by us and are protected by international copyright, trademark, patent, trade secret and other intellectual property or proprietary rights laws.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Termination
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">We may terminate your access to the Site, without cause or notice, which may result in the forfeiture and destruction of all information associated with you. All provisions of this Agreement that by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Links To Other Sites
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">Our Site may contain links to third-party sites that are not owned or controlled by us.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">The Site has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party sites or services. We strongly advise you to read the terms and conditions and privacy policy of any third-party site that you visit.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Governing Law
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">This Agreement (and any further rules, polices, or guidelines incorporated by reference) shall be governed and construed in accordance with the laws of California, United States, without giving effect to any principles of conflicts of law.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Changes To This Agreement
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">We reserve the right, at our sole discretion, to modify or replace these Terms of Service by posting the updated terms on the Site. Your continued use of the Site after any such changes constitutes your acceptance of the new Terms of Service.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">Please review this Agreement periodically for changes. If you do not agree to any of this Agreement or any changes to this Agreement, do not use, access or continue to access the Site or discontinue any use of the Site immediately.
      </p>
      <h1 class=" mt-2 text-2xl font-bold tracking-tight text-gray-900 sm:text-2xl"><b>Contact Us
      </b></h1>
      <p className="mt-2 text-xl leading-8 text-slate-950">If you have any questions about this Agreement, please contact us.</p>

    </div>
  )
};
