import { Disclaimer } from "../Disclaimer";
import { Footer } from "../Footer";
import Home from "../Home";

export function LandingPage() {
  return (
    <div>
      <Home />
      <Disclaimer />
      <Footer />
    </div>
  )
}