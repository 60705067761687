import screenV2 from "./assets/images/screenV2.png"
import marieCurieQuote from "./assets/images/marieCurieQuote.png"


export default function Home() {
  const HOP_LINK = "https://c7ff2cbgf6j105syo6yjtdmwi9.hop.clickbank.net"

  return (
    <div className="  sm:text-center  relative isolate overflow-hidden bg-white px-6  py-10 sm:py-32 lg:overflow-visible lg:px-0">

      <div className="
      md:w-full 
      lg:px-16
      xl:px-56
      2xl:px-96
      
      ">
        <div className="">
          <img
            className="w-full h-full sm:h-full sm:w-full"
            src={screenV2}
            alt="parenting"
          />
        </div>
        {/*  <div className="-ml-12 -mt-12 mt-0 p-12  lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden">
          <img
            className="w-[18rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[17rem]"
            src={together}
            alt="parenting"
          />
        </div> */}

        <div className="">
          <div className="">
            <div className="text-base leading-7 text-slate-950 ">

              <p className="mt-6 sm:mt-8 sm:text-3xl text-2xl leading-8 text-slate-950">
                <span class="font-semibold sm:text-3xl text-2xl">I</span>n the realm of Maria's early years, where doubts lingered among teachers about the abilities of the little girl, they deemed her condition <span class="font-semibold">unconventional and challenging</span>.
              </p>
              <p className="mt-2 sm:text-3xl text-2xl leading-8 text-slate-950">
                Nevertheless, her mother, Sofia, guided by a profound <span class="font-semibold">sense of motherhood</span>, instinctively knew otherwise.
              </p>

              {/* <img
                className="mx-auto w-[18rem] mt-8 max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 sm:w-[17rem]"
                src={together}
                alt="parenting"
              /> */}

              <p className="mt-8 sm:text-3xl text-2xl  leading-8 text-slate-950 " >
                Sofia firmly believed in the importance of <span class="font-semibold">early reading</span>, influencing her approach as she moved forward.

              </p>

              <p className="mt-8 sm:text-3xl text-2xl leading-8 text-slate-950 " >
                The dedicated mother ensured her daughter got a <a className="underline text-sky-500" href={HOP_LINK}>head start in reading.</a> <br></br> Words became her playground, igniting a passion that not only reshaped her own life, but lit the world ablaze. <span class="font-semibold">Marie Curie</span>, Nobel laureate and scientific pioneer, started right here.
              </p>

              <img
                className="mx-auto w-[21rem] sm:w-auto mt-8 max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 "
                src={marieCurieQuote}
                alt="parenting"
              />

              <p className="mt-8 sm:text-3xl text-2xl leading-8 text-slate-950 " >
                Listen, this true story is a perfect example that <span class="font-semibold">no teacher</span> will ever care more about your child's education than you as <span class="font-semibold">a mother</span> !</p>

              <p className="mt-8 sm:text-3xl text-2xl leading-8 text-slate-950 " >
                The most vital action we can take for our children is to introduce them to the <span class="font-semibold">joy of reading</span> at the <span class="font-semibold">earliest age</span> possible.
              </p>

              <p className="mt-8 sm:text-3xl text-2xl leading-8 text-slate-950 " >
                <a className="underline text-sky-500" href={HOP_LINK}>This</a> is an easy <a className="underline text-sky-500" href={HOP_LINK}>reading program</a> designed specifically for mothers like you, ensuring your child swiftly surpasses their peers in reading proficiency.
              </p>

              <p className="mt-8 sm:text-3xl text-2xl leading-8 text-slate-950 " >
                Even if they’re as young as 2, unfamiliar with the alphabet or currently show zero interest in reading whatsoever.
              </p>



              {/*   <p>
                Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris semper sed amet
                vitae sed turpis id. Id dolor praesent donec est. Odio penatibus risus viverra tellus varius sit neque
                erat velit. Faucibus commodo massa rhoncus, volutpat. Dignissim sed eget risus enim. Mattis mauris
                semper sed amet vitae sed turpis id.
              </p> */}

            </div>
          </div>
        </div>

      </div>
      <a href={HOP_LINK}>
        <button type="button" class=" mt-12  block   mx-auto   p-6 sm:pl-20 sm:pr-20 bg-orange-500  shadow-lg space-y-3 hover:bg-orange-400 
      sm:text-3xl text-2xl leading-8 text-white capitalize
      ">
          {"Yes! I want to unlock my child's reading potential now >>"}
        </button>
      </a>

      {/* <div class=" lg:px-20">
        <button type="button" class=" mt-8  block w-full  mx-auto  p-6 bg-orange-600  shadow-lg space-y-3 hover:bg-orange-500 ">
          <a className=" text-2xl leading-8 text-white capitalize " href="https://www.readingheadstart.com/">{"Yes! I want to unlock my child's reading potential now >>"} </a>
        </button>
      </div> */}
    </div>
  )
}
