import { Link } from "react-router-dom";

export default function Discloser() {
  return (
    <div class=" m-4 sm:m-40 block mt-8 sm:mt-8 text-sm text-black-950 text-center dark:text-gray-400">
      <Link to={`/`} class=" mt-2 underline text-5xl font-bold tracking-tight text-orange-500 sm:text-5xl">
        <b>Life Balance Lovers</b>
      </Link>

      <h1 class=" mt-10 text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
        <b>Discloser Agreement</b></h1>


      <p className="mt-2 text-xl leading-8 text-slate-950">This policy is valid from November 30, 2023
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">This website is a collaborative work written by a group of individuals. This blog accepts forms of cash advertising, sponsorship, paid insertions or other forms of compensation.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">The compensation received may influence the advertising content, topics or posts made in this site. That content, advertising space or post may not always be identified on the page itself as paid or sponsored content.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">The owner(s) of this site is compensated to provide opinion on products, services, websites and various other topics. Even though the owner of this site receive(s) compensation for posts or advertisements, we always give our honest opinions, findings, beliefs, or experiences on those topics or products. The views and opinions expressed on this site are purely the content creators' own. Any product claim, statistic, quote or other representation about a product or service should be verified with the manufacturer, provider or party in question.
      </p>
      <p className="mt-2 text-xl leading-8 text-slate-950">This site does not contain any content which might present a conflict of interest.</p>

    </div>
  )

};
