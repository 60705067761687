import { Link } from "react-router-dom";

export function Footer() {

  return (
    <footer class="bg-white  shadow  dark:bg-gray-800 mt-2 p-4 ">
      <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© {new Date().getFullYear()} <a href="https://www.lifebalancelovers.com/" class="hover:underline">Life Balance Lovers</a>. All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
          <li>
            <Link to={`privacy`} class="hover:underline me-4 md:me-6">Privacy Policy</Link>
          </li>
          <li>
            <Link to={`terms`} class="hover:underline me-4 md:me-6">Terms of Service</Link>
          </li>
          <li>
            <Link to={`dmca`} class="hover:underline me-4 md:me-6">DMCA</Link>
          </li>
          <li>

            <Link to={`discloser`} class="hover:underline">Discloser Agreement</Link>
          </li>
        </ul>
      </div>
    </footer>
  )
}